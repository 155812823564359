<template>
  <div class="text-white bg-neutral-100">
    <div
      class="relative flex flex-col h-full px-6 py-24 my-0 lg:px-24 max-w-7xl xl:mx-auto"
    >
      <h1 class="w-full mb-8 text-2xl font-bold text-neutral-900">
        Resources
      </h1>
      <div class="w-full grid grid-cols-0 md:grid-cols-2 gap-x-8 gap-y-4">
        <a
          :href="require('@/assets/forms/Participant Handout RMR - wave 2.pdf')"
          download
          class="flex p-4 items-center hover:bg-primary-800 bg-primary-700 rounded-lg w-full"
        >
          <span class="inline-block mx-0 my-auto text-2xl flaticon-pdf" />
          <div class="flex flex-col flex-1 m-0 ml-4 text-left">
            <h4 class="font-semibold text-xl">
              Account Upgrade Employee Handout
            </h4>
          </div>
        </a>
        <a
          :href="
            require('@/assets/forms/Participant Email Template - Wave 2.pdf')
          "
          download
          class="flex p-4 items-center hover:bg-primary-800 bg-primary-700 rounded-lg w-full"
        >
          <span class="inline-block mx-0 my-auto text-2xl flaticon-pdf" />
          <div class="flex flex-col flex-1 m-0 ml-4 text-left">
            <h4 class="font-semibold text-xl">
              Participant Email Template Handout
            </h4>
          </div>
        </a>
        <a
          href="/upgrade/wave-2-employees"
          target="_blank"
          class="flex p-2 items-center hover:bg-primary-800 bg-primary-700 rounded-lg w-full p-4"
        >
          <div class="m-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-box-arrow-up-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"
              />
              <path
                fill-rule="evenodd"
                d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"
              />
            </svg>
          </div>
          <div class="flex flex-col flex-1 m-0 ml-4 text-left">
            <h4 class="font-semibold text-xl">
              Participant Site
            </h4>
          </div>
        </a>
        <a
          :href="require('@/assets/forms/RMR HSA Investments.pdf')"
          download
          class="flex p-4 items-center hover:bg-primary-800 bg-primary-700 rounded-lg w-full"
        >
          <span class="inline-block mx-0 my-auto text-2xl flaticon-pdf" />
          <div class="flex flex-col flex-1 m-0 ml-4 text-left">
            <h4 class="font-semibold text-xl">
              RMR HSA Investments
            </h4>
          </div>
        </a>
      </div>
      <!-- Employer -->
      <!-- <div
        class="bg-neutral-lighter rounded-lg shadow mb-6 overflow-hidden p-6 mt-16"
      > -->
      <div class="bg-primary-700 rounded-lg shadow overflow-hidden p-5 mt-4">
        <div
          class="flex flex-row cursor-pointer items-center text-primary-700 text-white"
          @click="showEmployerForms = !showEmployerForms"
        >
          <h3 class="text-2xl leading-6 font-semibold text-white flex-1">
            Employer Communications
          </h3>
          <div class="text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-chevron-down"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
              />
            </svg>
          </div>
        </div>
        <transition @enter="enter" @after-enter="afterEnter" @leave="leave">
          <div
            class="grid grid-cols-0 sm:grid-cols-2 gap-x-4"
            v-show="showEmployerForms"
          >
            <div
              v-for="file in employerFiles"
              :key="file.file"
              class="w-full md:pr-4 mt-4 border-b md:border-b-1"
            >
              <div class="flex flex-row items-center text-base py-2">
                <div class="mr-4 flaticon-pdf text-white text-2xl"></div>
                <div class="flex-1 text-white">
                  {{ file.name }}
                </div>
                <a
                  :href="require(`@/assets/micrositeFormsWave2/${file.file}`)"
                  download
                  class="bg-neutral-lighter hover:bg-neutral-dark active:bg-primary rounded text-primary px-2 py-1 flex flex-row"
                  >Download <span class="ml-2 flaticon-down"></span
                ></a>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <!-- Participant -->
      <div
        class="bg-primary-700 rounded-lg shadow mb-6 overflow-hidden p-5 mt-4"
      >
        <div
          class="flex flex-row cursor-pointer items-center text-primary-700"
          @click="showParticpantForms = !showParticpantForms"
        >
          <h3 class="text-2xl leading-6 font-semibold text-white flex-1">
            Particpant Communications
          </h3>
          <div class="text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-chevron-down"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
              />
            </svg>
          </div>
        </div>
        <transition @enter="enter" @after-enter="afterEnter" @leave="leave">
          <div
            class="grid grid-cols-0 sm:grid-cols-2 gap-x-4"
            v-show="showParticpantForms"
          >
            <div
              v-for="file in participantFiles"
              :key="file.file"
              class="w-full md:pr-4 mt-4 border-b md:border-b-1"
            >
              <div class="flex flex-row items-center text-base py-2">
                <div class="mr-4 flaticon-pdf text-white text-2xl"></div>
                <div class="flex-1 text-white">
                  {{ file.name }}
                </div>
                <a
                  :href="require(`@/assets/micrositeFormsWave2/${file.file}`)"
                  download
                  class="bg-neutral-lighter hover:bg-neutral-dark active:bg-primary rounded text-primary px-2 py-1 flex flex-row"
                  >Download <span class="ml-2 flaticon-down"></span
                ></a>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <!-- White colored Dropdown  -->
      <!-- <div
        class="bg-neutral-lighter rounded-lg shadow mb-6 overflow-hidden p-6"
      >
        <div
          class="flex flex-row cursor-pointer items-center text-primary-700"
          @click="showParticpantForms = !showParticpantForms"
        >
          <h3 class="text-2xl leading-6 font-semibold text-primary flex-1">
            Participant Communications
          </h3>
        </div>
        <transition @enter="enter" @after-enter="afterEnter" @leave="leave">
          <div
            class="grid grid-cols-0 sm:grid-cols-2 gap-x-4"
            v-show="showParticpantForms"
          >
            <div
              v-for="file in participantFiles"
              :key="file.file"
              class="w-full md:pr-4 mt-4 border-b md:border-b-1"
            >
              <div class="flex flex-row items-center text-base py-2">
                <div class="mr-4 flaticon-pdf text-primary-700 text-2xl"></div>
                <div class="flex-1 text-primary-700">
                  {{ file.name }}
                </div>
                <a
                  :href="file.file"
                  download
                  class="bg-primary-light hover:bg-primary-mid active:bg-primary rounded text-white px-2 py-1 flex flex-row"
                  >Download <span class="ml-2 flaticon-down"></span
                ></a>
              </div>
            </div>
          </div>
        </transition>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showEmployerForms: false,
      showParticpantForms: false,
      employerFiles: [
        {
          name: "Employer Notice 6/10",
          file: "Employer Notice 06_10_2024.pdf"
        },
        {
          name: "Employer Notice 6/25",
          file: "Employer Notice 06_25_2024.pdf"
        },
        {
          name: "Employer Notice 7/11",
          file: "Employer Notice 07_11_2024.pdf"
        },
        {
          name: "Employer Notice 7/31",
          file: "Employer Notice 7_31_2024.pdf"
        },
        {
          name: "Employer Notice 8/8",
          file: "Employer Notice 8_8_2024.pdf"
        },
        {
          name: "Employer Notice 8/16",
          file: "Employer Notice 08_16_2024.pdf"
        }
      ],
      participantFiles: [
        {
          name: "Participant HSA Notice 7/12",
          file: "Participant HSA Notice 07_12_2024.pdf"
        },
        {
          name: "Participant HSA Notice 7/31",
          file: "Participant HSA Notice 07_31_2024.pdf"
        },
        {
          name: "Participant Notice 6/17",
          file: "Participant Notice 06_17_2024.pdf"
        },
        {
          name: "Participant Notice 7/12",
          file: "Participant Notice 7_12_2024.pdf"
        },
        {
          name: "Participant Notice 7/31",
          file: "Participant Notice 07_31_2024.pdf"
        },
        {
          name: "Participant Notice 8/8",
          file: "Participant Notice 08_08_2024.pdf"
        },
        {
          name: "Participant Notice 8/16",
          file: "Participant Notice 08_16_2024.pdf"
        }
      ]
    };
  },
  methods: {
    enter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = "absolute";
      element.style.visibility = "hidden";
      element.style.height = "auto";

      var height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      //Calculate transition time based on height
      let duration = (Math.floor(parseInt(height) / 100) + 1) * 0.2;

      // Trigger the animation.
      // We use `setTimeout` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      setTimeout(() => {
        element.style.transition = `height ${duration}s ease-in-out`;
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
      // element.style.padding = "1rem 1rem";
    },
    leave(element) {
      const height = getComputedStyle(element).height;

      element.style.height = height;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      //Calculate transition time based on height
      let duration = Math.floor(parseInt(height) / 100) * 0.15;

      setTimeout(() => {
        element.style.transition = `height ${duration}s ease-in-out`;
        element.style.height = 0;
      });
    }
  }
};
</script>

<style scoped>
.form-link:nth-last-of-type(2):nth-child(odd),
.form-link:last-of-type {
  border-bottom: 0;
}
</style>
