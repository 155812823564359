<template>
  <header>
    <Header />
    <view-header />
    <VideoOverview />
    <side-by-sides />
    <Timeline />
    <elevate-demo />
    <Resources />
    <Videos />
    <Support />
    <FAQs />
    <Future />
    <Footer />
  </header>
</template>

<script>
import Header from "./employerComponents/Header.vue";
import ViewHeader from "./employerComponents/ViewHeader.vue";
import SideBySides from "./employerComponents/SideBySides.vue";
import Timeline from "./employerComponents/Timeline.vue";
import ElevateDemo from "../wave-1/employerComponents/ElevateDemo.vue";
import Resources from "./employerComponents/Resources.vue";
import Support from "./employerComponents/Support.vue";
import Videos from "../QuickTipVideos/CurrentVideo.vue";
import VideoOverview from "./employerComponents/VideoOverview.vue";
import FAQs from "./employerComponents/FAQs.vue";
import Future from "./employerComponents/Future.vue";
import Footer from "./employerComponents/Footer.vue";

export default {
  metaInfo: {
    meta: [{ name: "robots", content: "noindex" }]
  },
  components: {
    Header,
    ViewHeader,
    SideBySides,
    Timeline,
    FAQs,
    ElevateDemo,
    Resources,
    Support,
    Future,
    Footer,
    VideoOverview,
    Videos
  },
  data() {
    return {
      windowWidth: window.innerWidth
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  computed: {
    isMobile: function() {
      return this.windowWidth < 992;
    }
  }
};
</script>
