<template>
  <div class="bg-white">
    <div
      class="mx-auto max-w-7xl divide-y divide-gray-900/10 px-6 py-24 sm:py-32 lg:px-24 lg:py-32"
    >
      <h2 class="text-2xl font-bold leading-10 tracking-tight text-gray-900">
        Frequently asked questions
      </h2>
      <dl class="mt-10 space-y-8 divide-y divide-gray-900/10">
        <div
          v-for="faq in faqs"
          :key="faq.id"
          :id="faq.id.toString()"
          class="pt-8 lg:grid lg:grid-cols-12 lg:gap-8"
        >
          <dt
            class="text-xl font-bold text-center px-4 py-8 lg:py-16 leading-7 text-gray-100 lg:col-span-5 bg-primary-700 flex items-center justify-center"
          >
            <div>
              {{ faq.question }}
            </div>
          </dt>
          <dd class="mt-4 lg:col-span-7 lg:mt-0 flex items-center">
            <div class="text-base leading-7 text-neutral-800">
              <p v-html="faq.answer"></p>
            </div>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      faqs: [
        {
          id: 1,
          question: "Why is RMR making this change?",
          answer:
            "<p>This new, modern, API-first consumer benefits administration platform delivers many upgrades, including instant claims processing and real-time claims reimbursements, among other advancements. This platform will allow us to administer benefits in a faster, more reliable way for both our employer clients and your employees.</p>"
        },
        {
          id: 3,
          question: "What will employers have to do during the transition?",
          answer:
            "<p>Employers will have a few minor transition steps, and RMR is here to help you through every step of the way. You will also have a new and improved employer dashboard to use post-transition.</p>"
        },
        {
          id: 4,
          question: "How will employees be impacted?",
          answer:
            "<p>We will transfer your employees existing accounts to our new platform and send new debit cards. There will be a short quiet period when funds will be unavailable while the funds are being transferred. Expenses are still eligible during this time but participants will need to pay out-of-pocket and submit a claim. After the transition date, employees will use the new portal.</p>"
        },
        {
          id: 5,
          question: "What bank will be the HSA banking custodian?",
          answer:
            "<p>UMB Bank, n.a. will be the banking custodian for HSAs.</p>"
        },
        {
          id: 6,
          question: "How will employees be notified?",
          answer:
            "<p>We will send a series of employee notices as well as a transition microsite for all the information that employees need. We will also provide you with communications and information that you can also use to communicate changes with employees.</p>"
        },
        {
          id: 7,
          question: "Will training and educational materials be provided?",
          answer:
            "<p>Yes, RMR will host training sessions and provide reference materials to familiarize your team with the new features and benefits.</p>"
        },
        {
          id: 8,
          question: "Will employees be getting a new debit card?",
          answer:
            "<p>Yes, in conjunction with an upgrade to our portal we are also upgrading your debit cards so employees will be receiving a new debit card with some new features, such as tap-to-pay and chip security.</p>"
        },
        {
          id: 9,
          question: "What is a quiet period?",
          answer:
            "<p>During the quiet period, access to your Rocky Mountain Reserve benefits accounts will be temporarily unavailable as we transfer administrative records to our new platform. Payroll deductions will continue as usual, with account access resuming once the quiet period ends. All deductions made during this time will be reflected accurately in your employees' accounts post-transition.</p><br><p style='font-style:italic; font-size:small; font-weight:bold;'>*For HSA transfers there is a different quiet period that ranges from 2-6 weeks.</p>"
        },
        {
          id: 10,
          question: "How do I liquidate my investments?",
          answer:
            "<p>Looking to liquidate your investments? Visit our dedicated page for step-by-step instructions and guidance on how to proceed. Click <a style='color:blue;' target='_blank' rel='noreferrer' href='https://www.rockymountainreserve.com/support/articles/29113579647379/'>here</a> to access the information you need.</p>"
        },
        {
          id: 11,
          question: "Does this apply to COBRA?",
          answer:
            "<p>Please be aware that this upgrade to our software pertains specifically to your Consumer Directed Health (CDH) benefits, including HSA, FSA, DCA, HRA, and others. If you have questions or concerns regarding COBRA benefits, please reach out to your account manager for assistance.</p>"
        },
        {
          id: 12,
          question: "What exactly is changing with this upgrade?",
          answer:
            "<p>We are changing three important pieces of the RMR experience.</p><p>(1) New employer and employee portals.</p><p>(2) New debit cards to add chips and contactless payments</p> <p>(3) A new & more integrated HSA banking relationship with UMB. At it's essence, this is a software change, not a change in your benefits services or coverage. The new software & debit cards will provide a more modern, secure, and user-friendly experience for both you and your employees.</p>"
        },
        {
          id: 17,
          question: "Will my spouse or dependents be getting new cards?",
          answer:
            "<p>New cards will not automatically be mailed for spouses and/or dependents. If you want a card for a family member you can log into the portal and order a new card(s) for your family member(s).</p>"
        }
      ]
    };
  }
};
</script>
